<template>
    <div>
        <b-card no-body>  
            
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6">
                        <b-card-title class="mb-0">Conexiones Actuales</b-card-title>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex justify-content-end">
                        <b-button size="sm" @click="getSessions" variant="primary">
                            <feather-icon icon="RefreshCwIcon" />
                            Actualizar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            
            <b-table
                striped
                hover
                :items="sessions"
                :fields="fields"
                :busy="loading"
                :empty-text="loading ? 'Loading sessions...' : 'No sessions found'"
                small
                responsive
            >
                <template #cell(devicE_ID)="data">
                    <span class="text-truncate truncate d-block" style="max-width: 100px;">{{ data.item.devicE_ID }}</span>
                </template>
                
                <template #cell(ipAddress)="data">
                    <b-link>{{ data.item.ipAddress }}</b-link>
                </template>

                <template #cell(userAgent)="data">
                    <b-tooltip :target="'tooltip-' + data.index" triggers="hover" placement="top">
                        {{ data.item.userAgent }}
                    </b-tooltip>
                    <b-link class="text-truncate truncate d-block" :id="'tooltip-' + data.index"
                    style="max-width: 200px;">{{ data.item.userAgent }}</b-link>
                </template>

                <template #cell(loginTime)="data">
                    <span class="text-capitalize">{{ formatDate(data.item.loginTime) }}</span>
                </template>

                <template #cell(actions)="data">
                    <b-button size="sm" @click="revokeSession(data.item)" variant="danger">Desconectar</b-button>
                </template>
            </b-table>
        </b-card>        
    </div>
</template>

<script>

import _ from "lodash";
import {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, 
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BFormCheckbox,
    BCardTitle,
} from "bootstrap-vue";
import store from '@/store'
import AuthProvider from '@/providers/Auth'
import { formatDate } from "@/@core/utils/filter";
const AuthResource = new AuthProvider()


export default {
    name: 'SettingsSessions',
    components: {
        BCard,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BFormGroup,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip, 
        VBTooltip,
        VBToggle,
        BSpinner,
        BSidebar,
        BFormCheckbox,
        BCardTitle,
    },
    data() {
        return {
            sessions: [],
            loading: false,
            fields: [
                {  key: 'devicE_ID', label: 'Dispositivo', },
                { key: 'userName', label: 'Usuario'},
                { key: 'connectionName', label: 'Conexión'},
                { key: 'ipAddress', label: 'IP', },
                { key: 'userAgent', label: 'Agente' },
                { key: 'loginTime', label: 'Fecha Conexión'},
                { key: 'actions', label: 'Acciones' }
            ]
        }
    },
    async mounted() {
        await this.getSessions()
    },
    methods: {
        async revokeSession(item) {
            const device_Id = store.getters['auth/getDeviceId']
            try {
                this.loading = true    
                await AuthResource.revokeToken(item.userId, device_Id)
                this.loading = false
                this.getSessions()

            } catch (error) {
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        },
        async getSessions () {
            this.loading = true
            try {
                const { data } = await AuthResource.getSessions()
                this.sessions = data
            } catch (error) {
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        },        
    }
}
</script>